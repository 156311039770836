<template>
  <section class="section keep-footer-off-page">
    <div
      class="p-5 container column box is-one-third-desktop is-half-tablet is-one-quarter-widescreen"
    >
      <form method="POST" action="">
        <!-- password stuff -->
        <b-field label="Password">
          <b-input type="password" placeholder="Password" password-reveal>
          </b-input>
        </b-field>

        <!-- password stuff -->
        <b-field label="Confirm Password">
          <b-input type="password" placeholder="Password" password-reveal>
          </b-input>
        </b-field>

        <!-- submit -->
        <div class="mt-3 button">Update Password</div>
      </form>
    </div>
  </section>
</template>
<script>
export default {
  name: "reset-token-view",
};
</script>
